

@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;500;700&display=swap');

*:not(ul):not(ol){
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;    
  text-decoration: none;
  /* scrollbar-width: 0px;
  scrollbar-width: 0px; */
}
/* *::-webkit-scrollbar{
  display: none;
} */
body,#root{
  background-color: #E5E5E5 !important;
  /* user-select: none; */
}
.MuiTypography-root{
  /* user-select: none;
   */
   
   caret-color:transparent !important;
}
/* @font-face {
  font-family: Helvetica;
  src: url('./assets/fonts//HelveticaNeueLTStd/HelveticaNeueLTStd-Bd.otf');
} */
/* *:not(ul):not(ol){
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;    
  text-decoration: none;
  scrollbar-width: 0px;
} */
* {
  font-family: 'Public Sans', sans-serif;

  font-weight: 400;
}
body,html,#root{
  height: 100%;
  width: 100%;
}
.map-container{
  height: 100%;
  width: 100%;
  
}
blockquote{
  /* display: unset !important; */
  padding-left: 10px !important;
  font-style: italic;
  border-left: 5px solid gray;
  background-color: #E5E5E5;
}
.react-transform-component,.react-transform-wrapper{
  height: 100% !important;
  width: 100% !important;
}
@keyframes textAnimation {
  0%{
    opacity: 0;
    transform: scale(0);
  }
  50%{
    opacity: 0.5;
    transform: scale(0.3);
  }
  100%{
    opacity: 1;
    transform: scale(1);
  }
}
.birthday-text{
  /* opacity: 0; */
  animation: textAnimation 1s linear ;
}
.ReactCrop__child-wrapper{
  height: 100%;
}